import React from "react";

function TwilioLogo() {
  return (
    <div>
      <img
        src="https://pi-company-assets.s3.amazonaws.com/bridgewidgets/logo.png"
        alt="BigCo Inc. logo"
        style={{ maxWidth: "200px" }}
      />
    </div>
  );
}

export default TwilioLogo;
