import { ChatIcon } from "@twilio-paste/icons/esm/ChatIcon";
import React from "react";
import { IconSize, TextColor } from "@twilio-paste/style-props";

export const LOGO_TITLE = "Bridge Widgets";
export const LOGO_SUB_TITLE = "Conversations";
export const APP_TITLE = "Bridge Widgets - Conversations";

interface ChatIconProps {
  decorative?: boolean;
  color?: TextColor | undefined;
  size?: IconSize | undefined;
  title?: string;
}

export const AppLogo: React.FC<ChatIconProps> = ({
  decorative = false,
  color = "colorTextBrandInverse",
  size = "sizeIcon40",
  title = "app logo",
}) => (
  <ChatIcon decorative={decorative} color={color} size={size} title={title} />
);
